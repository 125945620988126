@media screen and (min-width: 320px) {
    .offers-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }
}

@media screen and (min-width: 426px) {
    .offers-wrapper {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        justify-content: space-around;
        align-items: center;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }
}

.offer-item {
    /* Set the width for each item */
    text-align: center;
    opacity: 0;
    transform: translateX(100%);
    animation: slideIn 0.5s forwards ease-in-out;
}

@keyframes slideIn {
    to {
        opacity: 1;
        transform: translateX(0);
    }
}